<template>
  <div>

    <div class="col-14">
      <div class="card mb-1">
        
      <div style="text-align: right; ">
            <b-button 
               variant="outline-primary"    
              @click="toggleFullLayout"
             size="sm"
            >
            <feather-icon
            icon="Maximize2Icon"
            size="12"
          />
          
          </b-button>
        </div>

        <div class="card-header">
          <h4 class="card-title">
            <span>Match DDA</span>
          </h4>

          <b-col v-if="showFiltro ? false : true ">
            <div style="float:right">
               
              
              <div class="DivBotaoLadoLado"
                    v-if="skAD_DDA.ddaid == 0 || skTGFFIN.nufin == 0 ? false : true " >
                
                <b-button  variant="secondary"
                  type="submit"
                  @click="Match"
                >
                  <span>VINCULAR</span>
                </b-button>

               </div>

               <div class="DivBotaoLadoLado" >
                  <b-button v-b-toggle="'collapse-filtro'" variant="primary">
                    <span>FILTROS</span>
                  </b-button>
               </div>
               

               

            </div>
          </b-col>

        </div>

        <b-collapse visible  id="collapse-filtro">
        <div class="card-body">
          


          <b-row>
        <b-col
          md="6"
          xl="2"
          
        >
          <b-form-group
            label="Filtrar Por:"
          >
            <b-form-select
            v-model="FiltroTela.filtrarPorSelected"
            :options="filtrarPorOptions">
            </b-form-select>
          </b-form-group>
        </b-col>
  
        <b-col
          md="6"
          xl="4"
         
        >
          <b-form-group
            label="Valor:"
            
          >
            <b-form-input
              id="filtrarPorValue"
              placeholder=""
              v-model="FiltroTela.filtrarPorValue"
              required
              autocomplete="off"
            />
          </b-form-group>
        </b-col>


            <b-col md="6" xl="3" >
              <b-form-group
            label="Dt. Vencimento Inicial:"
            >
            <b-form-datepicker
                 id="DTVENCIni"
                 locale="pr-BR"
                 :date-format-options="{
                   year: 'numeric',
                   month: 'numeric',
                   day: 'numeric',
                 }"
                 today-button
                 reset-button
                 v-model="DTVENCIni"
                 placeholder="Selecione"
                 :required="true"
                 dropup 
               />
                  </b-form-group>
            </b-col>

            <b-col md="6" xl="3" >
              <b-form-group
            label="Dt Vencimento Final:"
            >
            <b-form-datepicker
                 id="DTVENCFin"
                 locale="pr-BR"
                 :date-format-options="{
                   year: 'numeric',
                   month: 'numeric',
                   day: 'numeric',
                 }"
                 today-button
                 reset-button
                 v-model="DTVENCFin"
                 placeholder="Selecione"
                 :required="true"
                 dropup 
               />
                  </b-form-group>
            </b-col>
        
          </b-row>


          <b-row>

       <b-col
        md="6"
        xl="4"
      >
        <b-form-group label="Consultar Dados:" >
          <b-form-radio-group>
            <input name="DadosConsultar" type="radio" id="DDA" value="DDA"
                 v-model="DadosConsultar" required> DDA
            <input name="DadosConsultar" type="radio" id="Financeiro" value="Financeiro"
                 v-model="DadosConsultar" required> Financeiro
            <input name="DadosConsultar" type="radio" id="Ambos" value="Ambos"
                 v-model="DadosConsultar" required> Ambos
          </b-form-radio-group>
        </b-form-group>
        
      </b-col>


    </b-row>
 

        <b-row>
            
            <b-col
                md="6"
                xl="12"
                class="mb-1"
              >
             
              <div class="DivBotaoLadoLado" >
            <b-button v-b-toggle="'collapse-filtro'"
              variant="primary"
              type="submit"
              @click="Consultar"
            >
              <span>CONSULTAR</span>
            </b-button>
              </div>
          </b-col>
         </b-row>
         
        </div>
        </b-collapse>
        
      </div>
    </div>

    
    <div class="row match-height">

    <div class="col-6" >
            <div class="card">
              <div class="card-body pb-10" >
               <div>
                 <h4 class="fw-bolder mb-1">DDA</h4>      
                 <b-form-input v-model="DDAfilter" placeholder="Filtro Rapido.."></b-form-input>            
                  <b-table
                  striped
                  hover
                  responsive
                  small
                  sticky-header
                  :items="TabDDA"
                  :fields="TabDDAfields"
                  :filter="DDAfilter"
                  table-class = "font-size11"
                  thead-class = "font-size11"
                  thead-tr-class = "font-size11"
                  select-mode = "single"
                  selectable
                  @row-selected="DDASelected"
                >

                <template #cell(vlrliquido)="row">
                     {{ 'R$ ' + TextFormat(row.value,'money') }}
                </template>

                 </b-table>

                 <div style="float:right">
                  Total:  {{ this.TabDDA.length }}
                 </div>
               </div>

             </div>
            </div>
    </div>

    <div class="col-6">
            <div class="card">
              <div class="card-body pb-10" >
               <div>
                 <h4 class="fw-bolder mb-1">Financeiro</h4>  
                 <b-form-input v-model="Financeirofilter" placeholder="Filtro Rapido.."></b-form-input>
                  <b-table
                  striped
                  hover
                  responsive
                  small
                  sticky-header
                  :items="TabFinanceiro"
                  :fields="TabFinanceirofields"
                  :filter="Financeirofilter"
                  table-class = "font-size11"
                  thead-class = "font-size11"
                  thead-tr-class = "font-size11"
                  selectable
                  @row-selected="FinanceiroSelected"
                  select-mode = "single"

                >

                <template #cell(vlrliquido)="row">
                     {{ 'R$ ' + TextFormat(row.value,'money') }}
                </template>

                
                 </b-table>

                 <div style="float:right">
                  Total:  {{ this.TabFinanceiro.length }}
                 </div>
               </div>

             </div>
            </div>
    </div>
 
    </div>

    <div class="col-14">
            <div class="card">
              <div class="card-body pb-10" >
               <div>
                 <h4 class="fw-bolder mb-1">Match</h4>  
                  <b-table
                  striped
                  hover
                  responsive
                  small
                  sticky-header
                  :items="TabMatch"
                  :fields="TabMatchfields"
                  table-class = "font-size11"
                  thead-class = "font-size11"
                  thead-tr-class = "font-size11"
                >

                <template #cell(id)="row">
                  <div class="DivBotaoLadoLado" >
                    <b-button 
                     variant="danger"
                     size="sm" @click="unMatch(row.value)" class="mr-1">
                      
                     <feather-icon
                         :icon="'Trash2Icon'"
                         size="15"
                    />

                    </b-button>
                  </div>
                </template>

                <template #cell(vlrliquido)="row">
                     {{ 'R$ ' + TextFormat(row.value,'money') }}
                </template>

                
                 </b-table>

                 <div style="float:right">
                  Total:  {{ this.TabMatch.length }}
                 </div>
               </div>

             </div>
            </div>
    </div>


    <div class="col-14" v-if="this.TabMatch.length > 0">
            <div class="card">
              <div class="card-body pb-1" >

                <div class="DivBotaoLadoLado" style="float:right" >
                     <b-button v-b-toggle="'collapse-filtro'"
                       variant="success"
                       type="submit"
                       @click="ConcluirMatch"
                     >
                       <span>CONCLUIR</span>
                     </b-button>
              </div>
               

             </div>
            </div>
    </div>
 


  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import VueApexCharts from 'vue-apexcharts'
import 'bootstrap-icons/font/bootstrap-icons.css'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
import Spinner from '@/components/Spinner.vue'
import UtilVUE from '@/customPortal/UtilVUE.vue'
import FiltrosPortal from '@/customPortal/FiltrosPortal.vue'

import {
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BTable,
  BFormSelect,
  BFormInput,
  BCollapse,
  BCard,
  VBToggle,
  BFormDatepicker,
  BFormCheckbox,
  BFormRadioGroup,
} from 'bootstrap-vue'

export default {
  components: {
    VueApexCharts,
    Multiselect,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BTable,
    BFormSelect,
    BFormInput,
    BCollapse,
    BCard,
    BFormDatepicker,
    BFormCheckbox,
    BFormRadioGroup,
  },
  directives: { 'b-toggle': VBToggle },
  data() {
    return {
      showFiltro: true,
      Financeirofilter:'',
      DDAfilter:'',
      FiltroTela: {
          TitloTela: 'MatchDDA',
          Selecionado: false,
          filtrarPorValue: '',
          filtrarPorSelected: 'NUMNOTA',
          FiltroComplementar: [],
        },
        filtrarPorOptions: [
          { value: 'NUMNOTA', text: 'Nº Nota' },
          { value: 'CGC_CPF', text: 'CPF/CNPJ' },
          { value: 'RAZAOSOCIAL', text: 'Razão Social' },
          { value: 'VLRLIQUIDO', text: 'Vlr. Liquido' },
        ],
        DTVENCIni: '',	
        DTVENCFin: '',
        DadosConsultar: 'Ambos',
        skTGFFIN:{
            nufin: 0,
            dtvenc: '',
          vlrliquido: 0,
          raiZ_CNPJ: ''
        },
        skAD_DDA:{
          ddaid: 0,
          ddaimpid: 0,
          dtvenc: '',
          vlrliquido: 0,
          raiZ_CNPJ: ''
        },
      TabDDA: [
      ],
      TabDDAfields: [
        { key: 'ddaid', label: 'ID'}.hide,
        { key: 'ddaimpid', label: 'DDAIMPID'}.hide,
        { key: 'numnota', label: 'Nro. Nota', sortable: true, tdClass: 'td90'  },
        { key: 'dtvenc', label: 'Dt. Venc.', sortable: true,tdClass: 'td80' },
        { key: 'vlrliquido', label: 'Vlr. Liquido', sortable: true,tdClass: 'td100' },
        { key: 'cgC_CPF', label: 'CPF/CNPJ', sortable: true, tdClass: 'td80'  },
        { key: 'razaosocial', label: 'Razão Social', sortable: true, tdClass: 'td250' },
        { key: 'codbarra', label: 'Cod. Barras', sortable: false,tdClass: 'td80' },
      ],
      TabFinanceiro: [
      ],
      TabFinanceirofields: [
        { key: 'nufin', label: 'NUFIN'}.hide,
        { key: 'numnota', label: 'Nro. Nota', sortable: true, tdClass: 'td90'  },
        { key: 'dtvenc', label: 'Dt. Venc.', sortable: true,tdClass: 'td80' },
        { key: 'vlrliquido', label: 'Vlr. Liquido', sortable: true,tdClass: 'td100' },
        { key: 'cgC_CPF', label: 'CPF/CNPJ', sortable: true, tdClass: 'td80'  },
        { key: 'razaosocial', label: 'Razão Social', sortable: true, tdClass: 'td250' }
        
        
      ],
      TabMatch: [
      ],
      TabMatchfields: [
        { key: 'id', label: ''},
        { key: 'ddaid', label: 'DDAID'}.hide,
        { key: 'ddaimpid', label: 'DDAIMPID'}.hide,
        { key: 'nufin', label: 'Nro. Fin', sortable: true, tdClass: 'td90'},
        { key: 'numnota', label: 'Nro. Nota', sortable: true, tdClass: 'td90'  },
        { key: 'dtvenc', label: 'Dt. Venc.', sortable: true,tdClass: 'td80' },
        { key: 'vlrliquido', label: 'Vlr. Liquido', sortable: true,tdClass: 'td100' },
        { key: 'cgC_CPF', label: 'CPF/CNPJ', sortable: true, tdClass: 'td80'  },
        { key: 'razaosocial', label: 'Razão Social', sortable: true, tdClass: 'td250' },
        { key: 'codbarra', label: 'Cod. Barras', sortable: false,tdClass: 'td80' },
      ],
      TabMatchFull: [
      ],

    }
  },
  mounted() {
      // Consulta Dados para Filtro na Tela
      // this.DescarregarFiltroTela()
      this.DTVENCIni = new Date()
      this.DTVENCFin = new Date()
    },
    methods: {
      FinanceiroSelected(item){
        
        if(item.length > 0)
        {

          if(item[0].nufin !== "" && item[0].nufin != undefined)
          {
            
            this.skTGFFIN = {
              nufin: item[0].nufin,
              dtvenc: item[0].dtvenc,
              vlrliquido: item[0].vlrliquido,
              raiZ_CNPJ: item[0].raiZ_CNPJ
            }

          }
        }


      },
      DDASelected(item){
      
        if(item.length > 0)
        {
          if(item[0].ddaimpid !== "" && item[0].ddaimpid != undefined)
          {
            this.skAD_DDA = {
              ddaid: item[0].ddaid,
              ddaimpid: item[0].ddaimpid,
              dtvenc: item[0].dtvenc,
              vlrliquido: item[0].vlrliquido,
              raiZ_CNPJ: item[0].raiZ_CNPJ
            }

          }
        }

      },
      BlockMatch(){
        this.skTGFFIN.nufin = 0 //Zera vinculo para ocultar botao Vincular
        this.skAD_DDA.ddaid = 0 //Zera vinculo para ocultar botao Vincular
      },
      async Consultar() {
        Spinner.StarLoad()

        //Inicio Dados Base Relatorio
        this.showFiltro = false
        const usuario = this.$session.get('usuario')
        let notification = ""
        //Fim Dados Base Relatorio

        let URLpost = ''

        this.BlockMatch()
        
        this.FiltroTela.FiltroComplementar = []
  
        if(this.DTVENCIni != "")
         {
           const Filtro = {
             parametro: 'DTVENCIni',
             valor: this.DTVENCIni,
           }
           this.FiltroTela.FiltroComplementar.push(Filtro)
         }

         if(this.DTVENCFin != "")
         {
           const Filtro = {
             parametro: 'DTVENCFin',
             valor: this.DTVENCFin,
           }
           this.FiltroTela.FiltroComplementar.push(Filtro)
         }

         if(this.DadosConsultar != "")
         {
           const Filtro = {
             parametro: 'DadosConsultar',
             valor: this.DadosConsultar,
           }
           this.FiltroTela.FiltroComplementar.push(Filtro)
         }
        this.CarregaFiltroTela()
  
        const ParametroJson = JSON.parse(JSON.stringify(this.FiltroTela))

        if(notification ==""){
            if(this.DadosConsultar == "Financeiro" || this.DadosConsultar == "Ambos") 
            {
    
              URLpost = `/DDA/ConsultaFinanceiro?CODUSU=${usuario.codusu}`
              
              this.TabFinanceiro = []
    
              await apiPortalSky.post(URLpost, ParametroJson, {
                headers: {
                  'Content-Type': 'application/json',
                },
              }).then(response => {
                if (response.status === 200) {
                  this.TabFinanceiro = response.data
                } else {
                  notification = ConexaoAPI.MsgFiltroSemRetorno()
                  AlertaMsg.FormataMsg(notification)
                }
              })
                .catch(error => {
                  notification = ConexaoAPI.ValidaErro(error)
                  AlertaMsg.FormataMsg(notification)
                })
            }
        }


        if(notification == ""){
            if(this.DadosConsultar == "DDA" || this.DadosConsultar == "Ambos") 
            {
    
              URLpost = `/DDA/ConsultaDDA?CODUSU=${usuario.codusu}`
              
              this.TabDDA = []
    
              await apiPortalSky.post(URLpost, ParametroJson, {
                headers: {
                  'Content-Type': 'application/json',
                },
              }).then(response => {
                if (response.status === 200) {
                  this.TabDDA = response.data
                } else {
                  notification = ConexaoAPI.MsgFiltroSemRetorno()
                  AlertaMsg.FormataMsg(notification)
                }
              })
                .catch(error => {
                  notification = ConexaoAPI.ValidaErro(error)
                  AlertaMsg.FormataMsg(notification)
                })
            }
       }
        
       if(notification === '') {
        this.RevisaMatch()
       }
         
        if(notification === '') {
         Spinner.EndLoad()
        }
      },
      async RevisaMatch(){
        
        //Percorre Lista removendo os que ja foi feito Match

        const TabDDATemp = [] // Lista Temporaria
        const TabFinanceiroTemp = [] // Lista Temporaria
        let AchouMatch = ""
        let notification = ""
        
        if (this.TabMatch.length > 0) {
   
        //Dados DDA
        if(notification ==""){
         
            /*Procura dentro da Tabela DDA*/
            this.TabDDA.forEach(DadosDDA => {
               
                AchouMatch = ""

                /*Procara dentro da Tabela de Match se ja nao foi incluido*/
                this.TabMatch.forEach(DadosMatch => {
                  if(DadosMatch.ddaid == DadosDDA.ddaid && DadosMatch.ddaimpid == DadosDDA.ddaimpid){
                    AchouMatch = "S"
                  }
                })

             
              if(AchouMatch !="S"){
                TabDDATemp.push(DadosDDA)
              }

            })

            this.TabDDA = []
            this.TabDDA = TabDDATemp

        }

        //Dados Financeiro
        if(notification ==""){
         
         /*Procura dentro da Tabela Financeiro*/
         this.TabFinanceiro.forEach(DadosFIN => {
           
             AchouMatch = ""        
          
             /*Procara dentro da Tabela de Match se ja nao foi incluido*/
             this.TabMatch.forEach(DadosMatch => {
               if(DadosMatch.nufin == DadosFIN.nufin ){
                 AchouMatch = "S"
               }

             })


           if(AchouMatch !="S"){
            TabFinanceiroTemp.push(DadosFIN)
           }

         })

         this.TabFinanceiro = []
         this.TabFinanceiro = TabFinanceiroTemp

        }
        
        }
      
      },
      CheckMatch(){
        let notification = ""
        let Validacao = ""

        if(this.skTGFFIN.dtvenc != this.skAD_DDA.dtvenc){
          Validacao += "<br>Data de Vencimento não são Iguais;"
        }

        if(this.skTGFFIN.vlrliquido != this.skAD_DDA.vlrliquido){
          Validacao += "<br>Valores não são Iguais;"
        }
        if(this.skTGFFIN.raiZ_CNPJ != this.skAD_DDA.raiZ_CNPJ){
          Validacao += "<br>Raiz do CNPJ não são Iguais;"
        }

         if(Validacao != ""){
           notification = { message: Validacao, alert_type: 'attention' }
         }
        return notification
      },
      async Match(){
        Spinner.StarLoad()
        const usuario = this.$session.get('usuario')
        let notification = ""

        let AchouDDA = "N"
        let AchouFINANCEIRO = "N"
        let AchouMatch = "N"

        let MatchDDA = ""
        let MatchFINANCEIRO = ""
        

        const TabDDATemp = [] // Lista Temporaria
        const TabFinanceiroTemp = [] // Lista Temporaria
        
        
        notification = this.CheckMatch()

        //Dados DDA
        if(notification ==""){
         
            /*Procura dentro da Tabela DDA*/
            this.TabDDA.forEach(DadosDDA => {
              
              AchouDDA = "N"
              
              if(DadosDDA.ddaid == this.skAD_DDA.ddaid && DadosDDA.ddaimpid == this.skAD_DDA.ddaimpid){
              
                AchouDDA = "S"

                /*Procara dentro da Tabela de Match se ja nao foi incluido*/
                this.TabMatch.forEach(DadosMatch => {
                  if(DadosMatch.ddaid == DadosDDA.ddaid && DadosMatch.ddaimpid == DadosDDA.ddaimpid){
                    AchouMatch = "S"
                  }

                })


                if(AchouMatch =="N"){
                  MatchDDA = DadosDDA
                }
                
              }

              if(AchouDDA =="N"){
                TabDDATemp.push(DadosDDA)
              }

            })

            this.TabDDA = []
            this.TabDDA = TabDDATemp

        }

        //Dados Financeiro
        if(notification ==""){
         
         /*Procura dentro da Tabela Financeiro*/
         this.TabFinanceiro.forEach(DadosFIN => {
           
          AchouFINANCEIRO = "N"
           
           if(DadosFIN.nufin == this.skTGFFIN.nufin ){
           
            AchouFINANCEIRO = "S"

             /*Procara dentro da Tabela de Match se ja nao foi incluido*/
             this.TabMatch.forEach(DadosMatch => {
               if(DadosMatch.nufin == DadosFIN.nufin ){
                 AchouMatch = "S"
               }

             })


             if(AchouMatch =="N"){
               MatchFINANCEIRO = DadosFIN
             }
             
           }

           if(AchouFINANCEIRO =="N"){
            TabFinanceiroTemp.push(DadosFIN)
           }

         })

         this.TabFinanceiro = []
         this.TabFinanceiro = TabFinanceiroTemp

        }
        
        if(notification ==""){
           if(AchouMatch =="N" && MatchDDA != "" && MatchFINANCEIRO != ""){
   
             
             let maxId = 1
              if (this.TabMatchFull.length > 0) {
                maxId += this.TabMatchFull.reduce(
                  (max, item) => (item.id > max ? item.id : max),
                  this.TabMatchFull[0].id,
                )
              }
   
              let NewMatch = {
               id:maxId,
               ddaid: MatchDDA.ddaid,
               ddaimpid: MatchDDA.ddaimpid,
               nufin: MatchFINANCEIRO.nufin,
               numnota: MatchFINANCEIRO.numnota,
               dtvenc: MatchFINANCEIRO.dtvenc,
               vlrliquido: MatchFINANCEIRO.vlrliquido,
               cgC_CPF: MatchFINANCEIRO.cgC_CPF,
               razaosocial: MatchFINANCEIRO.razaosocial,
               codbarra: MatchDDA.codbarra
              }
                           
              
              let NewMatchFull = {
               id:maxId,
               TabDDA: MatchDDA,
               TabFinanceiro: MatchFINANCEIRO,
              }
   
              this.TabMatch.push(NewMatch)
              //Grava dados em uma tabela FULL para Desfazer Match caso precise
              this.TabMatchFull.push(NewMatchFull)
   
           }
   
           this.BlockMatch()
        }


        if(notification !== '') {
          AlertaMsg.FormataMsg(notification)
       }else{
          Spinner.EndLoad()
       }
      },
      async unMatch(id){
        Spinner.StarLoad()
        const usuario = this.$session.get('usuario')
        let notification = ""

        let TabMatchTemp = []
        let TabMatchFullTemp = []
               
        if(notification ==""){
         
            this.TabMatch.forEach(DadosMatch => {
              if(DadosMatch.id != id){
                TabMatchTemp.push(DadosMatch)
              }
            })

            this.TabMatch = []
            this.TabMatch = TabMatchTemp

        }


        if(notification ==""){
         
         this.TabMatchFull.forEach(DadosMatchFull => {
           if(DadosMatchFull.id != id){
             TabMatchFullTemp.push(DadosMatchFull)
           }else{
            
            //Volta os Valores para a Selecao
            this.TabDDA.push(DadosMatchFull.TabDDA)
            this.TabFinanceiro.push(DadosMatchFull.TabFinanceiro)            
           }
         })

         this.TabMatchFull = []
         this.TabMatchFull = TabMatchFullTemp


        }

 
        if(notification === '') {
         Spinner.EndLoad()
        }
        
      },
      async ConcluirMatch() {
        Spinner.StarLoad()

        //Inicio Dados Base Relatorio
        this.showFiltro = false
        const usuario = this.$session.get('usuario')
        let notification = ""
        //Fim Dados Base Relatorio

        let URLpost = `/DDA/AtualizarDDAFinanceiro?CODUSU=${usuario.codusu}`
        const ParametroJson = JSON.parse(JSON.stringify(this.TabMatch))

        
        if(notification == ""){
    
              await apiPortalSky.post(URLpost, ParametroJson, {
                headers: {
                  'Content-Type': 'application/json',
                },
              }).then(response => {
                if (response.status === 200) {
                  this.TabMatch = []
                  notification = { message: "Match Realizado com Sucesso", alert_type: 'success' }
                } else {
                  notification = ConexaoAPI.MsgFiltroSemRetorno()
                }
              })
                .catch(error => {
                  notification = ConexaoAPI.ValidaErro(error)
                })
       }
        
        
       if(notification !== '') {
          AlertaMsg.FormataMsg(notification)
       }else{
          Spinner.EndLoad()
       }
      },
      TextFormat(valor, tipo) {
        return UtilVUE.TextFormat(valor, tipo)
      },
      CarregaFiltroTela() {
        const usuario = this.$session.get('usuario')
        usuario.filtrosTela = FiltrosPortal.AdcionarFiltro(usuario, this.FiltroTela)
        this.$session.set('usuario', usuario)
      },
      DescarregarFiltroTela() {
        const usuario = this.$session.get('usuario')
        const Filtrado = FiltrosPortal.RetornaFiltro(usuario, this.FiltroTela.TitloTela)
        if (Filtrado.Selecionado) {
          // Realiza Validacao de Campo da Tela para Executar Realmente o Filtro
          if (Filtrado.filtrarPorValue !== '') {
            this.FiltroTela = Filtrado// Carrega o Filtro
            
            // Descarrega Filtro Complementar
            this.FiltroTela.FiltroComplementar.forEach(ftComplementar => {
              
              switch (ftComplementar.parametro) {
                case "DTVENCIni":
                    this.DTVENCIni = ftComplementar.valor
                  break
                  case "DTVENCFin":
                    this.DTVENCFin = ftComplementar.valor
                  break
                  case "DadosConsultar":
                    this.DadosConsultar = ftComplementar.valor
                  break
              }


  
            })
  
            this.Consultar() // Executa o Filtro
          }
        }
      },
      toggleFullLayout() {
      UtilVUE.toggleFullLayout(this.$router, this.$route);
    }
    },
    computed: {
    },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
